
import { defineComponent } from 'vue';

const BaseModal = defineComponent({
  emits: ['close'],

  props: {
    title: String,
    overlay: {
      type: Boolean,
      default: true,
    },
    modalClasses: String,
  },
});

export default BaseModal;
