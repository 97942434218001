import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_BaseButton = _resolveComponent("BaseButton")

  return (_openBlock(), _createBlock(_component_BaseButton, {
    class: "mt-10 px-5 md:px-10 w-full",
    size: "lg",
    loading: _ctx.pendingRedirect,
    onClick: _ctx.onGoogleAuth
  }, {
    default: _withCtx(() => [
      _createVNode(_component_font_awesome_icon, {
        class: "mr-2",
        icon: ['fab', 'google']
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t(`actions.${_ctx.buttonType}-google`)), 1)
    ]),
    _: 1
  }, 8, ["loading", "onClick"]))
}