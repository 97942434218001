
import { computed, defineComponent, ref } from 'vue';
import BaseInput from '@/components/base/BaseInput.vue';

const FilterSearch = defineComponent({
  components: { BaseInput },

  emits: ['update:modelValue', 'visible', 'hidden'],

  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const inputVisible = ref<boolean>(false);
    const searchString = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    const toggleInput = () => {
      inputVisible.value = !inputVisible.value;
      searchString.value = '';
      return !inputVisible.value
        ? emit('visible')
        : emit('hidden');
    };

    const searchIcon = computed(() => (
      inputVisible.value ? ['fas', 'times'] : ['fas', 'search']
    ));

    return {
      searchString,
      inputVisible,
      toggleInput,
      searchIcon,
    };
  },
});

export default FilterSearch;
