import axios from '@/setup/axios';
import SuggestedImage from '@/types/SuggestedImageType';
import SuggestionRequest from '@/types/SuggestionRequest';
import { AxiosError, AxiosResponse } from 'axios';
import ImageDownloadSize from '@/types/ImageDownloadSize';
import { setAxiosHeaders } from '@/utils/api';
import * as Sentry from '@sentry/vue';

const useImageSuggest = () => {
  const fetchImages = async (formData: SuggestionRequest): Promise<SuggestedImage[] | boolean> => (
    axios.post(
      '/.netlify/functions/image-suggest-fetch-images',
      { formData },
      {
        ttl: 86400, // ttl = time to live in seconds. Cache persist for 24 hours
      },
    )
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => { Sentry.captureException(error); })
  );

  const downloadImage = async (url: string, unsplashLink = '', size: ImageDownloadSize) => (
    axios.post(
      '/.netlify/functions/image-suggest-download-image',
      {
        url,
        unsplashLink,
        size,
      },
      {
        ttl: 86400, // ttl = time to live in seconds. Cache persist for 24 hours
        headers: setAxiosHeaders(),
      },
    )
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => { Sentry.captureException(error); })
  );

  return { fetchImages, downloadImage };
};

export default useImageSuggest;
