
import {
  computed, ComputedRef, defineComponent, ref, watch,
} from 'vue';
import SuggestedImagesFilters from '@/components/SuggestedImage/SuggestedImagesFilters.vue';
import MasonryLayout from '@/components/MasonryLayout.vue';
import SuggestedImagesSceleton from '@/components/SuggestedImage/SuggestedImagesSceleton.vue';
import SuggestedImage from '@/components/SuggestedImage/SuggestedImage.vue';
import SuggestedImageType from '@/types/SuggestedImageType';
import RequireSignUp from '@/components/SuggestedImage/RequireSignUp.vue';
import SearchPlatforms from '@/constants/SearchPlatforms';
import SuggestionFilters from '@/types/SuggestionFilters';

const SuggestedImages = defineComponent({
  components: {
    SuggestedImagesFilters,
    MasonryLayout,
    SuggestedImagesSceleton,
    SuggestedImage,
    RequireSignUp,
  },

  props: {
    images: {
      type: Object as () => SuggestedImageType[],
      default: Object as () => [],
    },
    loading: Boolean,
    requireAuth: Boolean,
  },

  setup(props) {
    const loadedImages = ref<number>(0);
    const brokenImages = ref<string[]>([]);

    const selectedDatabases = computed(() => (Object.values(SearchPlatforms).filter(
      (platform) => props.images.some((image) => image.database === platform),
    )));

    const filterOptions = ref<SuggestionFilters>({
      platforms: [],
      orientations: [],
      searchString: '',
    });

    const filteredImages = computed(() => (
      props.images
        .filter((img: SuggestedImageType) => (
          filterOptions.value.platforms.includes(img.database as SearchPlatforms)
        ))
        .filter((img) => (!filterOptions.value.searchString
          || img.text?.tags?.includes(filterOptions.value.searchString)
          || img.text?.description?.toLowerCase().split(' ').includes(filterOptions.value.searchString)
          || img.text?.alt?.toLowerCase().split(' ').includes(filterOptions.value.searchString)))
        .filter((img) => {
          let orientation = 'horizontal';
          if (img.height > img.width) orientation = 'vertical';
          if (img.height === img.width) orientation = 'square';

          return filterOptions.value.orientations.length === 0
            || filterOptions.value.orientations.includes(orientation);
        })
    ));

    const progress = computed(() => loadedImages.value * (100 / props.images.length));

    const allImagesLoaded: ComputedRef<boolean> = computed(
      (): boolean => (props.images.length === 0 || loadedImages.value >= filteredImages.value.length),
    );

    const onError = (item: string): void => {
      loadedImages.value += 1;
      brokenImages.value.push(item);
    };

    const isBroken = (item: string): boolean => brokenImages.value.indexOf(item) > -1;

    watch(() => props.images, () => {
      filterOptions.value.platforms = selectedDatabases.value;
      loadedImages.value = 0;
    });

    return {
      onError,
      isBroken,
      brokenImages,
      allImagesLoaded,
      loadedImages,
      filterOptions,
      filteredImages,
      selectedDatabases,
      progress,
    };
  },
});

export default SuggestedImages;
