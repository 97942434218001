
import { defineComponent, inject, computed } from 'vue';
import UserProfileDropdown from '@/components/UserProfileDropdown.vue';
import AuthState from '@/types/AuthState';

const Header = defineComponent({
  components: {
    UserProfileDropdown,
  },

  setup() {
    const { authState } = inject('AuthModule') as { authState: AuthState };

    const userName = computed(() => authState.user?.name);
    const isAuthenticated = computed(() => authState.isAuthenticated);

    return { isAuthenticated, userName };
  },
});

export default Header;
