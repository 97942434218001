
import {
  computed, defineComponent, inject, ref, onMounted, watch,
} from 'vue';

import DefaultLayout from '@/components/layout/DefaultLayout.vue';
import TextSearchArea from '@/components/TextSearch/TextSearchArea.vue';
import SuggestedImages from '@/components/SuggestedImage/SuggestedImages.vue';
import useImageSuggest from '@/mixins/useImageSuggest';
import SuggestedImageType from '@/types/SuggestedImageType';
import RequestStatus from '@/constants/RequestStatus';
import TextSearchAreaTabs from '@/constants/TextSearchAreaTabs';
import SuggestionRequest from '@/types/SuggestionRequest';
import BaseAlertMessage from '@/components/base/BaseAlertMessage.vue';
import useFaunaDB from '@/mixins/useFaunaDB';
import AuthState from '@/types/AuthState';
import { useRouter, useRoute } from 'vue-router';
import LimitReachedModal from '@/components/modals/LimitReachedModal.vue';
import SearchPlatforms from '@/constants/SearchPlatforms';
import Footer from '@/components/Footer.vue';

const TheDashboard = defineComponent({
  components: {
    DefaultLayout,
    TextSearchArea,
    SuggestedImages,
    BaseAlertMessage,
    LimitReachedModal,
    Footer,
  },

  setup() {
    const { fetchImages } = useImageSuggest();
    const { updateUserRecord, retrieveUserRecord, updateRequestRecord } = useFaunaDB();

    const { authState, update } = inject('AuthModule') as { authState: AuthState; update: Function };

    const router = useRouter();
    const route = useRoute();

    const status = ref<RequestStatus>(RequestStatus.Initial);
    const searchString = ref<string>('');
    const selectedTab = ref<TextSearchAreaTabs>(TextSearchAreaTabs.YourText);
    const images = ref<SuggestedImageType[]>([]);
    const limitReached = ref<boolean>(false);
    const shoudlRequireAuth = ref<boolean>(false);

    const isInitial = computed(() => status.value === RequestStatus.Initial);
    const isLoading = computed(() => status.value === RequestStatus.Loading);
    const isError = computed(() => status.value === RequestStatus.Error);

    const noResults = computed(() => status.value === RequestStatus.Success && images.value.length === 0);

    const onRedirectToMembership = () => router.push({ name: 'membership' });

    const checkLimit = async (searchType: TextSearchAreaTabs) => {
      if (!authState.isAuthenticated) {
        return false;
      }

      const response = await retrieveUserRecord();

      if (
        searchType === TextSearchAreaTabs.YourText
        && response.data.nlpRequestCount < response.data.currentNlpRequestLimit
      ) {
        return response.data;
      }

      if (
        searchType === TextSearchAreaTabs.ByTopic
        && response.data.requestCount < response.data.currentRequestLimit
      ) {
        return response.data;
      }

      return false;
    };

    const onChangeTab = () => {
      status.value = RequestStatus.Initial;
    };

    const onSubmit = async (data: SuggestionRequest) => {
      // Prevent double click
      if (isLoading.value) {
        return;
      }

      status.value = RequestStatus.Loading;

      const sendOn = new Date().getTime();

      const user = await checkLimit(data.submission_type);

      if (authState.isAuthenticated && !user) {
        limitReached.value = true;
        selectedTab.value = data.submission_type;
        status.value = RequestStatus.Initial;

        return;
      }

      images.value = [];

      const response = await fetchImages(data);

      if (typeof response === 'boolean' || !response) {
        await updateRequestRecord(
          '/api/image-suggestion',
          {
            submission_type: data.submission_type,
            language: data.language,
            image_database: data.image_database,
          } as SuggestionRequest,
          [],
          sendOn,
          500,
        );

        status.value = RequestStatus.Error;

        return;
      }

      if (response.length > 0 && authState.isAuthenticated) {
        const requestCount = data.submission_type === TextSearchAreaTabs.YourText
          ? { nlpRequestCount: user.nlpRequestCount + 1 }
          : { requestCount: user.requestCount + 1 };

        update({ ...user, ...requestCount });

        const userRecordResult = await updateUserRecord();
        const requestRecordResult = await updateRequestRecord(
          '/api/image-suggestion',
          {
            submission_type: data.submission_type,
            language: data.language,
            image_database: data.image_database,
          } as SuggestionRequest,
          response,
          sendOn,
          200,
        );

        if (!userRecordResult || !requestRecordResult) {
          status.value = RequestStatus.Error;

          return;
        }
      }

      if (!authState.isAuthenticated) {
        shoudlRequireAuth.value = true;
        localStorage.setItem('saved-image-request', JSON.stringify({
          submitted_text: data.submitted_text,
          submission_type: data.submission_type,
          language: data.language,
          image_database: data.image_database,
        }));
      } else {
        shoudlRequireAuth.value = false;
      }

      status.value = RequestStatus.Success;

      const imagesSection = document.querySelector('#suggested-images');
      if (imagesSection) {
        imagesSection.scrollIntoView({ behavior: 'smooth' });
      }

      images.value = response;
    };

    onMounted(async () => {
      // If user was redirected from ImageSuggest widget
      if (route.query.type && route.query.text) {
        selectedTab.value = route.query.type as TextSearchAreaTabs;
        searchString.value = route.query.text as string;

        await onSubmit({
          submitted_text: route.query.text as string,
          submission_type: route.query.type as TextSearchAreaTabs,
          language: route.query.lang as string,
          image_database: SearchPlatforms.Pixabay,
        });
      }
    });

    watch(() => authState.isAuthenticated, async () => {
      if (!authState.isAuthenticated) return;

      // If user made request before sign in/up
      const savedRequestJSON = localStorage.getItem('saved-image-request');
      let savedRequest;

      if (savedRequestJSON) {
        savedRequest = JSON.parse(savedRequestJSON);
        localStorage.removeItem('saved-image-request');
        await onSubmit({ ...savedRequest });
      }
    });

    return {
      onSubmit,
      searchString,
      images,
      isLoading,
      isError,
      selectedTab,
      noResults,
      isInitial,
      limitReached,
      onRedirectToMembership,
      shoudlRequireAuth,
      onChangeTab,
    };
  },
});

export default TheDashboard;
