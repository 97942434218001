
import {
  defineComponent,
  computed,
  inject,
  ref,
} from 'vue';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import TextSearchAreaTabs from '@/constants/TextSearchAreaTabs';
import AuthState from '@/types/AuthState';
import SubscriptionPlans from '@/types/SubscriptionPlans';
import { VueI18n } from 'vue-i18n';

const LimitReachedModal = defineComponent({
  components: { BaseButton, BaseModal },

  emits: ['update:modelValue', 'redirect'],

  props: {
    modelValue: Boolean,
    loading: Boolean,
    selectedSearchType: String,
  },

  setup(props, { emit }) {
    const { authState } = inject('AuthModule') as { authState: AuthState };
    const { plans } = inject('PlansModule') as { plans: SubscriptionPlans };
    const i18n: VueI18n | undefined = inject('i18n');

    const renewalDateInMilliseconds = ref<number>(authState.user?.renewalDate * 1000);

    const localValue = computed({
      get: (): boolean => props.modelValue,
      set: (value: boolean): void => emit('update:modelValue', value),
    });

    const isAutoSuggestion = computed(() => (props.selectedSearchType === TextSearchAreaTabs.YourText));
    const canUpgrade = computed(() => authState.user?.subscription.price !== plans.standardPlan.price);
    const renewalDateString = computed(() => i18n?.d(new Date(renewalDateInMilliseconds.value), 'short'));
    const manualSearchLimit = computed(() => authState.user?.currentRequestLimit);

    return {
      localValue,
      isAutoSuggestion,
      canUpgrade,
      renewalDateString,
      manualSearchLimit,
    };
  },
});

export default LimitReachedModal;
