import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "w-max md:m-auto" }
const _hoisted_3 = { class: "absolute right-0 top-0.5 sm:top-0" }
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = { class: "text-area relative" }
const _hoisted_6 = { class: "md:bg-dark h-full md:bg-opacity-40 flex flex-col items-center pt-14" }
const _hoisted_7 = { class: "w-full max-w-screen-2xl m-auto px-5 md:px-20 lg:px-40 xl:px-72" }
const _hoisted_8 = { class: "text-white mb-5 text-center md:hidden" }
const _hoisted_9 = { class: "text-24 font-bold" }
const _hoisted_10 = { class: "text-center md:text-right mt-3" }
const _hoisted_11 = { class: "flex flex-col md:flex-row justify-end" }
const _hoisted_12 = { class: "flex items-center justify-between px-5 lg:px-24 w-full py-5 text-9 md:text-12 text-white" }
const _hoisted_13 = { href: "\n                https://www.pexels.com/photo/brown-wooden-dock-462024/?utm_source=imagesuggest&utm_medium=referral\n              " }
const _hoisted_14 = { href: "https://www.pexels.com?utm_source=imagesuggest&utm_medium=referral" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_TextSearchAdvancedSettings = _resolveComponent("TextSearchAdvancedSettings")
  const _component_router_link = _resolveComponent("router-link")
  const _component_BaseAlertMessage = _resolveComponent("BaseAlertMessage")
  const _component_AutomaticSearchField = _resolveComponent("AutomaticSearchField")
  const _component_ManualSearchField = _resolveComponent("ManualSearchField")
  const _component_BaseButton = _resolveComponent("BaseButton")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
        return (_openBlock(), _createBlock("div", {
          key: index,
          class: [{ 'text-tab-main border-tab-main' : _ctx.isActiveTab(tab) }, "inline-block pb-1.5 md:pb-3.5 text-secondary hover:text-tab-main\n        hover:border-tab-main border-transparent border-b-4 sm:leading-6 px-5 cursor-pointer text-12 md:text-14"],
          onClick: ($event: any) => (_ctx.changeTab(tab))
        }, _toDisplayString(_ctx.$t(`dashboard.text-search-area.tabs.${tab}.label`)), 11, ["onClick"]))
      }), 128))
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode("div", _hoisted_4, [
        _createVNode("div", {
          class: [
            `inline-block text-secondary hover:text-tab-main sm:leading-6 md:pb-2.5 px-2 cursor-pointer mr-2
            border-b-4 border-transparent`,
            _ctx.isSettingsVisible && 'text-tab-main border-btn-primary'
          ],
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSettings && _ctx.toggleSettings(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'cog'],
            class: "align-baseline text-18 md:text-22"
          })
        ], 2),
        (_ctx.isSettingsVisible)
          ? (_openBlock(), _createBlock(_component_TextSearchAdvancedSettings, {
              key: 0,
              modelValue: _ctx.suggestionSettings,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.suggestionSettings = $event)),
              class: "absolute right-0 sm:right-1 top-14 sm:top-8 md:top-10 sm:mt-0.5 w-screen sm:w-96 z-40 px-20 sm:px-0",
              onCancel: _ctx.toggleSettings
            }, null, 8, ["modelValue", "onCancel"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode("div", _hoisted_6, [
        _createVNode("div", _hoisted_7, [
          _createVNode("div", _hoisted_8, [
            _createVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('dashboard.title')), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('dashboard.sub-title')), 1)
          ]),
          _renderSlot(_ctx.$slots, "alert"),
          _createVNode(_component_BaseAlertMessage, {
            modelValue: _ctx.isMaxTextLength,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.isMaxTextLength = $event)),
            class: "mb-4 py-5"
          }, {
            default: _withCtx(() => [
              (_ctx.canUpgrade)
                ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t(`messages.max-${_ctx.requestParameters.submission_type}-words-1`)) + " ", 1),
                    _createVNode(_component_router_link, {
                      class: "font-semibold lowercase underline",
                      to: "membership"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('actions.upgrade')), 1)
                      ]),
                      _: 1
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t(`messages.max-${_ctx.requestParameters.submission_type}-words-2`, { count: _ctx.planLimit })), 1)
                  ], 64))
                : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t(`messages.max-${_ctx.requestParameters.submission_type}-words-pro`, { count: _ctx.planLimit })), 1)
                  ], 64))
            ]),
            _: 1
          }, 8, ["modelValue"]),
          (_ctx.isActiveTab(_ctx.yourTextTab))
            ? (_openBlock(), _createBlock(_component_AutomaticSearchField, {
                key: 0,
                modelValue: _ctx.requestParameters.submitted_text,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.requestParameters.submitted_text = $event))
              }, null, 8, ["modelValue"]))
            : (_openBlock(), _createBlock(_component_ManualSearchField, {
                key: 1,
                modelValue: _ctx.requestParameters.submitted_text,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.requestParameters.submitted_text = $event))
              }, null, 8, ["modelValue"])),
          _createVNode("div", _hoisted_10, [
            _createVNode("div", _hoisted_11, [
              _createVNode(_component_BaseButton, {
                class: "w-full md:w-max",
                type: "submit",
                loading: _ctx.loading,
                disabled: _ctx.isMaxTextLength || !_ctx.requestParameters.submitted_text,
                onClick: _ctx.onSearch
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('actions.suggest-images')), 1)
                ]),
                _: 1
              }, 8, ["loading", "disabled", "onClick"])
            ])
          ])
        ]),
        _createVNode("div", _hoisted_12, [
          _createVNode("div", null, " © " + _toDisplayString(new Date().getFullYear()) + " " + _toDisplayString(_ctx.$t('dashboard.copyright-brand')), 1),
          _createVNode("div", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('dashboard.photo-by')) + " ", 1),
            _createVNode("a", _hoisted_13, _toDisplayString(_ctx.$t('dashboard.pixabay')), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('dashboard.from')) + " ", 1),
            _createVNode("a", _hoisted_14, _toDisplayString(_ctx.$t('dashboard.pexels')), 1)
          ])
        ])
      ])
    ])
  ]))
}