import User from '@/types/User';

export function setAxiosHeaders() {
  const user: User = JSON.parse(localStorage.getItem('user') || 'null');
  const token = user?.token;

  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
}
