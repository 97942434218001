
import {
  computed, defineComponent, inject, onMounted,
} from 'vue';
import AuthState from '@/types/AuthState';
import SubscriptionPlans from '@/types/SubscriptionPlans';

const PlanLabel = defineComponent({
  setup() {
    const { authState } = inject('AuthModule') as { authState: AuthState };
    const { plans } = inject('PlansModule') as { plans: SubscriptionPlans };

    const requestLeft = computed(() => {
      const result = authState.user.currentNlpRequestLimit - authState.user.nlpRequestCount;
      return result && result < 0 ? 0 : result;
    });

    const percentage = computed(() => (requestLeft.value
      ? ((requestLeft.value / authState.user.currentNlpRequestLimit) * 100)
      : 0));

    const canUpgrade = computed(() => authState.user.subscription.price !== plans.standardPlan.price);

    const setProgress = (percent: number): void => {
      const circle: SVGCircleElement | null = document.querySelector('.circle-progress');

      if (!circle) return;

      const radius = circle.r.baseVal.value;
      const circumference = radius * 2 * Math.PI;

      circle.style.strokeDasharray = `${circumference} ${circumference}`;
      circle.style.strokeDashoffset = `${circumference}`;

      const offset = circumference - (percent / 100) * circumference;
      if (circle) circle.style.strokeDashoffset = offset.toString();
    };

    onMounted(() => {
      setProgress(percentage.value);
    });

    return {
      requestLeft, percentage, canUpgrade,
    };
  },
});

export default PlanLabel;
