import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronDown, faCheckSquare, faCog, faSearch, faCloudDownloadAlt, faTimes, faEye,
  faInfoCircle, faEyeSlash, faPen, faCircle, faFileAlt, faCalendarDay, faChevronUp, faUserCircle,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import i18n from '@/setup/i18n';
import ClickOutside from '@/directives/click-outside';
import AuthModule from '@/store/AuthModule';
import PlansModule from '@/store/PlansModule';
import * as Sentry from '@sentry/vue';

import '@/setup/validationRules';

import '@/assets/styles/tailwind.scss';

library.add(
  faChevronDown, faCheckSquare, faCog, faSearch, faCloudDownloadAlt, faTimes, faEye, faUserCircle,
  faInfoCircle, faEyeSlash, faPen, faCircle, faFileAlt, faCalendarDay, faChevronUp, faSquare, faGoogle,
  faCheck,
);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon)
  .directive('click-outside', ClickOutside)
  .use(router)
  .use(i18n) // for template
  .provide('i18n', i18n.global) // for script section
  .provide('PlansModule', PlansModule())
  .provide('AuthModule', AuthModule())
  .mount('#app');

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  autoSessionTracking: true,
  attachProps: true,
  trackComponents: true,
});
