export default {
  pixabay: [
    'en',
    'pt-BR',
    'pt-PT',
    'bg',
    'zh-CN',
    'cs',
    'da',
    'nl',
    'fi',
    'de',
    'el',
    'hu',
    'id',
    'ja',
    'ko',
    'no',
    'pl',
    'ro',
    'ru',
    'sk',
    'es',
    'sv',
    'th',
    'tr',
    'vi',
  ],
  pexels: [
    'en',
    'pt-BR',
    'pt-PT',
    'ca',
    'zh-CN',
    'zh-TW',
    'cs',
    'da',
    'nl',
    'fi',
    'fr',
    'de',
    'el',
    'hu',
    'id',
    'it',
    'ja',
    'ko',
    'no',
    'pl',
    'ro',
    'ru',
    'sk',
    'es',
    'sv',
    'th',
    'tr',
    'uk',
    'vi',
  ],
  unsplash: [
    'en',
    'pt-BR',
    'bg',
    'ca',
    'zh-CN',
    'zh-TW',
    'cs',
    'da',
    'nl',
    'fi',
    'fr',
    'de',
    'el',
    'hu',
    'id',
    'it',
    'ja',
    'ko',
    'no',
    'pl',
    'ro',
    'ru',
    'sk',
    'es',
    'sv',
    'th',
    'tr',
    'uk',
    'vi',
  ],
};
