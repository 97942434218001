import { vModelText as _vModelText, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "absolute bottom-2 left-3 text-icon-secondary text-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _withDirectives(_createVNode("textarea", {
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.localValue = $event)),
      id: "submittest-text",
      name: "submitted_text",
      class: "block w-full h-64 border border-input-main rounded text-12 md:text-14\n      text-search-area px-7 py-6 focus:outline-primary placeholder-gray-300 leading-7",
      placeholder: _ctx.$t('dashboard.text-search-area.tabs.html.placeholder')
    }, null, 8, ["placeholder"]), [
      [_vModelText, _ctx.localValue]
    ]),
    (_ctx.localValue)
      ? (_openBlock(), _createBlock("span", _hoisted_2, _toDisplayString(_ctx.contentWords) + " " + _toDisplayString(_ctx.$t('dashboard.text-search-area.words')), 1))
      : _createCommentVNode("", true)
  ]))
}