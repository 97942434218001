import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import TheDashboard from '@/views/TheDashboard.vue';
import AuthModule from '@/store/AuthModule';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'dashboard',
    component: TheDashboard,
    meta: {
      title: 'ImageSuggest',
    },
  },
  {
    path: '/my-account',
    name: 'my-account',
    component: () => import(/* webpackChunkName: "account" */ '@/views/TheUserAccount.vue'),
    meta: {
      requireAuth: true,
      title: 'ImageSuggest - My Account',
    },
  },
  {
    path: '/membership',
    name: 'membership',
    component: () => import(/* webpackChunkName: "membership" */ '@/views/TheMembership.vue'),
    meta: {
      requireAuth: true,
      title: 'ImageSuggest - Membership',
    },
  },
  {
    path: '/faq-help',
    name: 'faq-&-help',
    component: () => import(/* webpackChunkName: "faq" */ '@/views/TheFAQ.vue'),
    meta: {
      requireAuth: true,
      title: 'ImageSuggest - FAQ',
    },
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import(/* webpackChunkName: "signIn" */ '@/views/TheSignIn.vue'),
    meta: {
      title: 'ImageSuggest - Sign In',
    },
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import(/* webpackChunkName: "signUp" */ '@/views/TheSignUp.vue'),
    meta: {
      title: 'ImageSuggest - Sign Up',
    },
  },
  {
    path: '/reset-password-request',
    name: 'reset-password-request',
    component: () => import(/* webpackChunkName: "resetPasswordRequest" */ '@/views/TheResetPasswordRequest.vue'),
    meta: {
      title: 'ImageSuggest - Reset password',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "resetPassword" */ '@/views/TheResetPassword.vue'),
    meta: {
      title: 'ImageSuggest - Reset password',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const { authState } = AuthModule();

  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // If URL contain authorization code, redirect to dashboard
  if (to.query.code) {
    next({ name: 'dashboard', hash: `#authorization_code=${to.query.code}` });
  }

  if (to.meta.requireAuth && !authState.isAuthenticated) {
    next({ name: 'sign-in' });
  } else {
    next();
  }
});

export default router;
