import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-white text-dark-blue absolute z-50\n      right-0 w-48 rounded-md shadow-dropdown overflow-hidden"
}
const _hoisted_2 = {
  key: 0,
  class: "border-menu-dark"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")

  return (_openBlock(), _createBlock("div", {
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dropdownVisible = false))
  }, [
    _createVNode("div", {
      class: "text-10 cursor-pointer hover:opacity-50",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t('dashboard.image-download.download-size')) + " ", 1),
      _createVNode(_component_font_awesome_icon, {
        key: _ctx.dropdownVisible,
        icon: _ctx.dropdownVisible ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']
      }, null, 8, ["icon"])
    ]),
    (_ctx.dropdownVisible)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.downloadOptions, (option, index) => {
            return (_openBlock(), _createBlock("div", { key: index }, [
              _createVNode("div", {
                class: ["py-3 px-3 hover:bg-list-hover", _ctx.isCurrentOption(option) && 'text-btn-primary'],
                onClick: ($event: any) => (_ctx.localValue = option)
              }, _toDisplayString(_ctx.$t(`dashboard.image-download.size-${index}`)) + " (" + _toDisplayString(option.width) + "x" + _toDisplayString(option.height) + ") ", 11, ["onClick"]),
              (index === 0)
                ? (_openBlock(), _createBlock("hr", _hoisted_2))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 32))
}