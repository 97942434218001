import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "bg-body h-full min-h-screen font-sans text-14 relative" }
const _hoisted_2 = {
  key: 0,
  class: "absolute w-full h-5/6 flex items-center justify-center"
}
const _hoisted_3 = {
  key: 1,
  class: "absolute w-full h-5/6 flex items-center justify-center text-input-danger text-18"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")
  const _component_BaseAlertMessage = _resolveComponent("BaseAlertMessage")
  const _component_BaseLoading = _resolveComponent("BaseLoading")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createVNode(_component_BaseAlertMessage, {
      modelValue: _ctx.emailConfirmed,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.emailConfirmed = $event)),
      class: "w-2/4 mt-1 absolute z-50 transform translate-x-2/4",
      variant: "warning",
      close: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('messages.email-was-confirmed')), 1)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_ctx.isLoading || _ctx.isError || _ctx.loading || _ctx.error || _ctx.isSubscriptionsError)
      ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
          (_ctx.isLoading || _ctx.loading)
            ? (_openBlock(), _createBlock("div", _hoisted_2, [
                _createVNode(_component_BaseLoading, {
                  class: "h-20",
                  "bar-width": "10",
                  variant: "primary"
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.isError || _ctx.error || _ctx.isSubscriptionsError)
            ? (_openBlock(), _createBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('errors.system')), 1))
            : _createCommentVNode("", true)
        ], 64))
      : _renderSlot(_ctx.$slots, "default", { key: 1 })
  ]))
}