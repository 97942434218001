import { inject } from 'vue';
import { useStripe } from '@/mixins/useStripe';
import User from '@/types/User';

const useSignIn = () => {
  const { checkRenewalDate } = useStripe();
  const { logIn } = inject('AuthModule') as { logIn: Function };

  const handleSignIn = async (userRecord: User): Promise<boolean> => {
    logIn(userRecord);

    const renewalDateInMilliseconds = userRecord.renewalDate * 1000;
    const renewalDateResponse = await checkRenewalDate(
      renewalDateInMilliseconds,
      userRecord.stripeCustomerId,
    );

    if (!renewalDateResponse) return false;

    return true;
  };

  return { handleSignIn };
};

export default useSignIn;
