import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "my-12 w-80" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")
  const _component_BaseModal = _resolveComponent("BaseModal")

  return (_ctx.localValue)
    ? (_openBlock(), _createBlock(_component_BaseModal, {
        key: 0,
        "modal-classes": "px-20",
        title: _ctx.isAutoSuggestion ? _ctx.$t('limit-reached-modal.title') : _ctx.$t('limit-reached-modal.title-manual'),
        onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.localValue = false))
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_1, [
            (_ctx.isAutoSuggestion)
              ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.canUpgrade
            ? _ctx.$t('limit-reached-modal.message-auto-free', { date: _ctx.renewalDateString })
            :  _ctx.$t('limit-reached-modal.message-auto-paid', { date: _ctx.renewalDateString })), 1)
                ], 64))
              : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.canUpgrade
            ? _ctx.$t('limit-reached-modal.message-manual-free', { date: _ctx.renewalDateString, count: _ctx.manualSearchLimit })
            :  _ctx.$t('limit-reached-modal.message-manual-paid', { date: _ctx.renewalDateString, count: _ctx.manualSearchLimit })), 1)
                ], 64))
          ]),
          (_ctx.canUpgrade)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 0,
                loading: _ctx.loading,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('redirect')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('actions.upgrade')), 1)
                ]),
                _: 1
              }, 8, ["loading"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}