
import { computed, defineComponent, ref } from 'vue';
import SearchPlatformCheckbox from '@/components/SearchPlatformCheckbox.vue';
import SearchPlatforms from '@/constants/SearchPlatforms';
import SelectOption from '@/types/SelectOption';

const FilterSources = defineComponent({
  components: { SearchPlatformCheckbox },

  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: Array as () => SearchPlatforms[],
      required: true,
    },
    platformOptions: {
      type: Array as () => SelectOption[],
      default: () => [],
    },
    actionsVisible: Boolean,
  },

  setup(props, { emit }) {
    const dropdownVisible = ref<boolean>(false);

    const selectedPlatforms = computed({
      get: () => props.modelValue,
      set: (value: SearchPlatforms[]) => emit('update:modelValue', value),
    });

    const toggleDropdown = () => {
      dropdownVisible.value = !dropdownVisible.value;
    };

    return {
      dropdownVisible,
      toggleDropdown,
      selectedPlatforms,
    };
  },
});

export default FilterSources;
