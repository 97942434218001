import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative md:hidden" }
const _hoisted_2 = {
  key: 0,
  class: "bg-white absolute shadow-dropdown z-50 p-5 right-0 rounded-md"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_SearchPlatformCheckbox = _resolveComponent("SearchPlatformCheckbox")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", {
        class: "cursor-pointer",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t('actions.sources')) + " ", 1),
        _createVNode(_component_font_awesome_icon, {
          key: _ctx.dropdownVisible,
          class: ["ml-2", _ctx.dropdownVisible ? 'text-icon-primary' : 'text-icon-dark'],
          icon: ['fas', _ctx.dropdownVisible ? 'chevron-up' : 'chevron-down']
        }, null, 8, ["class", "icon"])
      ]),
      (_ctx.dropdownVisible)
        ? (_openBlock(), _createBlock("div", _hoisted_2, [
            _createVNode(_component_SearchPlatformCheckbox, {
              modelValue: _ctx.selectedPlatforms,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectedPlatforms = $event)),
              options: _ctx.platformOptions,
              variant: "primary",
              "checkbox-classes": "my-4",
              "checkbox-label-classes": "text-12 md:text-14 mr-7",
              "unchecked-icon-classes": "inline align-bottom",
              "checked-icon-classes": "inline align-bottom",
              class: ["mb-3 lg:mb-0", !_ctx.actionsVisible && 'lg:hidden']
            }, null, 8, ["modelValue", "options", "class"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_SearchPlatformCheckbox, {
      modelValue: _ctx.selectedPlatforms,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedPlatforms = $event)),
      options: _ctx.platformOptions,
      variant: "primary",
      "checkbox-classes": "inline",
      "checkbox-label-classes": "text-12 md:text-14 mr-7",
      "unchecked-icon-classes": "inline align-bottom",
      "checked-icon-classes": "inline align-bottom",
      class: ["hidden md:flex", !_ctx.actionsVisible && 'lg:hidden']
    }, null, 8, ["modelValue", "options", "class"])
  ]))
}