
import {
  computed, WritableComputedRef, defineComponent, ref,
} from 'vue';
import { Field, ErrorMessage } from 'vee-validate';
import BaseLabel from '@/components/base/BaseLabel.vue';

const BaseInput = defineComponent({
  components: { BaseLabel, Field, ErrorMessage },

  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: String || Number,
      required: true,
    },
    label: String,
    name: {
      type: String,
      required: true,
    },
    placeholder: String,
    iconLeft: Boolean,
    inputClasses: String,
    labelClasses: String,
    togglable: Boolean,
    plaintext: Boolean,
    isValid: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    const inputVisible = ref<boolean>(false);

    const localValue: WritableComputedRef<string | number> = computed({
      get: (): string | number => props.modelValue,
      set: (value: string | number): void => emit('update:modelValue', value),
    });

    const toggleVisibility = (): void => {
      inputVisible.value = !inputVisible.value;
    };

    return { localValue, toggleVisibility, inputVisible };
  },
});

export default BaseInput;
