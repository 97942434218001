
import {
  computed, defineComponent, inject, ref, watch,
} from 'vue';
import RequestStatus from '@/constants/RequestStatus';
import useImageSuggest from '@/mixins/useImageSuggest';
import AuthState from '@/types/AuthState';
import SuggestedImageType from '@/types/SuggestedImageType';
import ImageDownloadSize from '@/types/ImageDownloadSize';
import DownloadSizesSelect from '@/components/SuggestedImage/DownloadSizesSelect.vue';
import * as Sentry from '@sentry/vue';

const SuggestedImage = defineComponent({
  components: { DownloadSizesSelect },

  emits: ['load', 'error'],

  props: {
    item: {
      type: Object as () => SuggestedImageType,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { downloadImage } = useImageSuggest();

    const { authState } = inject('AuthModule') as { authState: AuthState };

    const status = ref<RequestStatus>(RequestStatus.Initial);
    const selectedDownloadSize = ref<ImageDownloadSize>({
      width: props.item.width,
      height: props.item.height,
    });

    const isLoading = computed(() => status.value === RequestStatus.Loading);
    const isError = computed(() => status.value === RequestStatus.Error);
    const isSuccess = computed(() => status.value === RequestStatus.Success);

    const isAuthenticated = computed(() => authState.isAuthenticated);

    const downloadOptions = computed(() => {
      const sizes = [1, 0.75, 0.5, 0.25];
      const options: ImageDownloadSize[] = [];

      sizes.forEach((size) => {
        options.push({
          width: Math.round(props.item.width * size),
          height: Math.round(props.item.height * size),
        });
      });

      return options;
    });

    const onDownload = async () => {
      if (!isAuthenticated.value) return;

      status.value = RequestStatus.Loading;

      // props.item.download.special is for unsplash special download link only
      const response = await downloadImage(
        props.item.download.url,
        props.item.download.special,
        selectedDownloadSize.value,
      );

      if (typeof response === 'boolean' || !response) {
        status.value = RequestStatus.Error;
        Sentry.captureException(new Error('Image download failed'));

        return;
      }

      const byteCharacters = atob(response);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i += 1) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpeg' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${new Date().getTime()}.jpeg`);
      link.click();
      status.value = RequestStatus.Success;
    };

    const onError = (item: string): void => emit('error', item);

    watch(() => selectedDownloadSize.value, () => {
      status.value = RequestStatus.Initial;
    });

    return {
      onError,
      onDownload,
      isLoading,
      isError,
      isSuccess,
      isAuthenticated,
      downloadOptions,
      selectedDownloadSize,
    };
  },
});

export default SuggestedImage;
