
import { defineComponent, ref } from 'vue';

const BaseLabel = defineComponent({
  props: {
    label: String,
    tooltip: [String, Boolean],
    tooltipPosition: {
      type: String,
      default: 'right',
      validator: (value: string): boolean => (
        ['top', 'right', 'left', 'bottom'].indexOf(value) !== -1
      ),
    },
    labelClasses: [String, Boolean],
    tooltipIconClasses: String,
  },

  setup() {
    const tooltipVisible = ref<boolean>(false);

    return { tooltipVisible };
  },
});

export default BaseLabel;
