
import { defineComponent, onMounted, ref } from 'vue';
import BaseButton from '@/components/base/BaseButton.vue';
import useAuth from '@/mixins/useAuth';
import { useRoute, useRouter } from 'vue-router';

const GoogleAuthButton = defineComponent({
  components: { BaseButton },

  props: {
    buttonType: {
      type: String,
      default: 'sign-up',
    },
  },

  setup() {
    const { googleAuth } = useAuth();
    const route = useRoute();
    const router = useRouter();

    const pendingRedirect = ref<boolean>(false);

    const onGoogleAuth = async () => {
      pendingRedirect.value = true;
      await googleAuth();
    };

    onMounted(() => {
      const [name] = route.hash.substring(1).split('=');
      // If route contain acces_token, this is a google auth
      // redirect to DefaultLayout and keep token in URL
      if (name === 'access_token') {
        router.push({
          name: 'dashboard',
          hash: route.hash,
        });
      }
    });

    return {
      pendingRedirect,
      onGoogleAuth,
    };
  },
});

export default GoogleAuthButton;
