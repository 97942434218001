import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLabel = _resolveComponent("BaseLabel")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")

  return (_openBlock(), _createBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(_component_BaseLabel, {
          key: 0,
          class: "mb-2",
          "label-classes": "text-input-label font-semibold",
          "tooltip-icon-classes": "hover:text-input-label text-18",
          label: _ctx.label,
          tooltip: _ctx.tooltip
        }, null, 8, ["label", "tooltip"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createBlock("div", {
        class: [
        `cursor-pointer flex items-center`,
        _ctx.checkboxClasses,
      ],
        key: index,
        onClick: ($event: any) => (!_ctx.isDisabled(option) && _ctx.toggleValue(option.value))
      }, [
        (_ctx.isChecked(option.value))
          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 0,
              icon: ['fas', 'check-square'],
              class: [[
          _ctx.uncheckedIconClasses,
          `text-icon-${_ctx.variant}`,
          _ctx.isDisabled(option) && 'opacity-40',
        ], "text-24 mr-1.5 block"]
            }, null, 8, ["class"]))
          : (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 1,
              icon: ['far', 'square'],
              class: [[
          _ctx.checkedIconClasses,
          !_ctx.isDisabled(option) ? `hover:text-icon-${_ctx.variant}` : 'opacity-40',
        ], "text-icon-secondary text-24 mr-1.5 block"]
            }, null, 8, ["class"])),
        _createVNode("span", { class: _ctx.checkboxLabelClasses }, [
          _createVNode(_component_BaseLabel, {
            label: option.text,
            "label-classes": _ctx.isDisabled(option) && 'opacity-40',
            tooltip: 
            _ctx.isDisabled(option)
            && _ctx.$t(`dashboard.text-search-area.advanced-settings.platform-to-search-on.not-supported`)
          
          }, null, 8, ["label", "label-classes", "tooltip"])
        ], 2)
      ], 10, ["onClick"]))
    }), 128))
  ]))
}