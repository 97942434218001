
import { defineComponent, WritableComputedRef, computed } from 'vue';

const BaseAlertMessage = defineComponent({
  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (value: string): boolean => (
        ['primary', 'warning', 'danger'].indexOf(value) !== -1
      ),
    },
    close: Boolean,
  },

  setup(props, { emit }) {
    const localValue: WritableComputedRef<boolean> = computed({
      get: (): boolean => props.modelValue,
      set: (value: boolean): void => emit('update:modelValue', value),
    });

    return { localValue };
  },
});

export default BaseAlertMessage;
