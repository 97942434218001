
import { defineComponent } from 'vue';

const BaseLoading = defineComponent({
  props: {
    barWidth: {
      type: String,
      default: '4',
    },
    variant: {
      type: String,
      default: 'white',
      validator: (value: string): boolean => (
        ['white', 'primary', 'secondary', 'success', 'danger', 'dark'].indexOf(value) !== -1
      ),
    },
  },
});

export default BaseLoading;
