
import {
  defineComponent, inject, ref, computed,
} from 'vue';
import { VueI18n } from 'vue-i18n';
import SelectOption from '@/types/SelectOption';
import SuggestionFilters from '@/types/SuggestionFilters';
import SearchPlatforms from '@/constants/SearchPlatforms';
import AuthState from '@/types/AuthState';
import FilterSearch from '@/components/FilterSearch.vue';
import FilterSources from '@/components/FilterSources.vue';

const SuggestedImagesFilters = defineComponent({
  components: { FilterSources, FilterSearch },

  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: Object as () => SuggestionFilters,
      required: true,
    },
    suggested: Number,
    selectedDatabases: {
      type: Object as () => SearchPlatforms[],
      default: Object as () => [],
    },
  },

  setup(props, { emit }) {
    const i18n: VueI18n | undefined = inject('i18n');
    const { authState } = inject('AuthModule') as { authState: AuthState };

    const actionsVisible = ref<boolean>(true);
    const selectedOrientations = ref<string[]>(props.modelValue.orientations);
    const filterOptions = computed(() => ({
      platforms: props.modelValue.platforms,
      orientations: props.modelValue.orientations,
      searchString: props.modelValue.searchString,
    }));

    const selectedPlatforms = computed({
      get: () => props.selectedDatabases,
      set: (value) => emit('update:modelValue', { ...filterOptions.value, platforms: value }),
    });

    const searchString = computed({
      get: () => props.modelValue.searchString,
      set: (value) => emit('update:modelValue', { ...filterOptions.value, searchString: value }),
    });

    const platformOptions: SelectOption[] = props.selectedDatabases.map((platform) => ({
      value: platform,
      text: i18n && i18n.t(`dashboard.text-search-area.advanced-settings.platform-to-search-on.options.${platform}`),
    }));

    const isAuthenticated = computed(() => authState.isAuthenticated);

    const toggleOrientation = (value: string) => {
      setTimeout(() => {
        if (selectedOrientations.value.indexOf(value) >= 0) {
          selectedOrientations.value.splice(selectedOrientations.value.indexOf(value), 1);
        } else {
          selectedOrientations.value.push(value);
        }
      });

      emit('update:modelValue', { ...filterOptions.value, orientations: selectedOrientations.value });
    };

    return {
      platformOptions,
      selectedPlatforms,
      searchString,
      toggleOrientation,
      selectedOrientations,
      isAuthenticated,
      actionsVisible,
    };
  },
});

export default SuggestedImagesFilters;
