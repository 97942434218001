
import { defineComponent, watch } from 'vue';
import Masonry from 'masonry-layout';

const MasonryLayout = defineComponent({
  props: {
    items: {
      type: Object as () => string[],
      default: Object as () => [],
    },
    height: {
      type: Number,
      default: 500,
    },
    contentLoaded: Boolean,
  },

  setup(props) {
    const createLayout = () => new Masonry('.masonry-with-columns', {
      itemSelector: '.grid-item',
      columnWidth: '.grid-sizer',
      horizontalOrder: true,
      percentPosition: true,
    });

    watch(() => props.contentLoaded, (value) => {
      if (value) setTimeout(() => createLayout()); // setTimeout is required here, do not remove
    });

    watch(() => props.items, () => {
      setTimeout(() => createLayout());
    }, { immediate: true });
  },

});

export default MasonryLayout;
