
import { defineComponent, ref, nextTick } from 'vue';

const ManualSearchField = defineComponent({
  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const isInputFocused = ref<boolean>(false);
    const keywords = ref<string[]>([]);
    const localValue = ref<string>('');

    const onKeyPress = async (event: KeyboardEvent) => {
      localValue.value = localValue.value.replace(',', '');
      const shouldAdd = (event.code === 'Comma' || event.code === 'Enter');
      const lastIndex = keywords.value.length - 1;

      keywords.value.splice(lastIndex, 1, localValue.value);

      if (shouldAdd && localValue.value) {
        await nextTick();
        setTimeout(() => {
          keywords.value.push('');
          localValue.value = '';
        });
      }

      if (event.code === 'Backspace' && localValue.value === '') {
        await nextTick();
        setTimeout(() => {
          if (!keywords.value[lastIndex]) {
            keywords.value.splice(lastIndex - 1, 1);
          } else {
            keywords.value.splice(lastIndex, 1);
          }
          localValue.value = '';
        });
      }

      setTimeout(() => {
        const finalValue = keywords.value.filter((v) => v).join(', ');
        emit('update:modelValue', finalValue);
      });
    };

    const removeIcon = (index: number) => {
      keywords.value.splice(index, 1);
      emit('update:modelValue', keywords.value.join(', '));
    };

    return {
      localValue,
      isInputFocused,
      keywords,
      onKeyPress,
      removeIcon,
    };
  },
});

export default ManualSearchField;
