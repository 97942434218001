import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")

  return (_ctx.localValue)
    ? (_openBlock(), _createBlock("div", {
        key: 0,
        class: `bg-alert-${_ctx.variant} rounded-md px-5 py-2 flex items-center justify-between`
      }, [
        _createVNode("div", null, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'info-circle'],
            class: `mr-4 text-alert-icon-${_ctx.variant} text-18`
          }, null, 8, ["class"]),
          _createVNode("span", {
            class: `text-alert-${_ctx.variant}`
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2)
        ]),
        (_ctx.close)
          ? (_openBlock(), _createBlock("div", {
              key: 0,
              class: "cursor-pointer",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.localValue = false))
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'times'],
                class: `text-alert-icon-${_ctx.variant}`
              }, null, 8, ["class"])
            ]))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}