
import { defineComponent } from 'vue';
import SignUpForm from '@/components/forms/SignUpForm.vue';
import BaseCard from '@/components/base/BaseCard';
import BaseAlertMessage from '@/components/base/BaseAlertMessage.vue';
import useSignUp from '@/mixins/useSignUp';

const RequireSignUp = defineComponent({
  components: {
    SignUpForm,
    BaseCard,
    BaseAlertMessage,
  },

  ...useSignUp,
});

export default RequireSignUp;
