import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "text-input-danger text-left text-12 mt-0.5" }
const _hoisted_2 = {
  key: 2,
  class: "text-input-plaintext"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLabel = _resolveComponent("BaseLabel")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_Field = _resolveComponent("Field")
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")

  return (_openBlock(), _createBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(_component_BaseLabel, {
          key: 0,
          "label-classes": "text-input-label font-semibold",
          "tooltip-icon-classes": "hover:text-input-label text-18",
          class: `mb-2 ${_ctx.labelClasses}`,
          label: _ctx.label,
          tooltip: _ctx.tooltip
        }, null, 8, ["class", "label", "tooltip"]))
      : _createCommentVNode("", true),
    _createVNode("div", {
      class: [
      `inline-block relative focus-within:text-input-main ${_ctx.inputClasses}`,
      _ctx.isValid ? 'text-input-main' : 'text-input-danger'
    ]
    }, [
      (!_ctx.plaintext)
        ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
            (_ctx.icon || _ctx.clearable)
              ? (_openBlock(), _createBlock("div", {
                  key: 0,
                  class: [
            'absolute inset-y-0 flex items-center pl-2 text-input-icon cursor-pointer',
            _ctx.iconLeft ? 'left-1' : 'right-3'
          ],
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleIconClick && _ctx.handleIconClick(...args)))
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: _ctx.clearable ? ['fas', 'times'] : _ctx.icon
                  }, null, 8, ["icon"])
                ], 2))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      (!_ctx.plaintext)
        ? (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_Field, {
              modelValue: _ctx.localValue,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.localValue = $event)),
              class: [
            `border h-full w-full rounded-md py-2 px-4 focus:outline-primary
            focus:border-input-focus placeholder-gray-300`,
            _ctx.icon && _ctx.iconLeft ? 'pl-8' : 'pr-8',
            _ctx.clearable && 'pr-8',
            _ctx.isValid ? 'border-input-main' : 'border-input-danger'
          ],
              name: _ctx.name,
              type: _ctx.inputType,
              placeholder: _ctx.placeholder,
              rules: _ctx.rules
            }, null, 8, ["modelValue", "class", "name", "type", "placeholder", "rules"]),
            _createVNode("div", _hoisted_1, [
              _createVNode(_component_ErrorMessage, { name: _ctx.name }, null, 8, ["name"])
            ])
          ], 64))
        : (_openBlock(), _createBlock("div", _hoisted_2, _toDisplayString(_ctx.localValue), 1))
    ], 2)
  ]))
}