import { inject } from 'vue';
import Stripe from 'stripe';
import axios from '@/setup/axios';
import Plan from '@/types/Plan';
import StripeSubscription from '@/types/StripeSubscription';
import AuthState from '@/types/AuthState';
import useFaunaDB from '@/mixins/useFaunaDB';
import { AxiosResponse, AxiosError } from 'axios';
import SubscriptionConfigurationType from '@/types/SubscriptionConfigurationType';
import { setAxiosHeaders } from '@/utils/api';
import * as Sentry from '@sentry/vue';

export const findPrice = (product: Stripe.Product, prices: Stripe.Price[]) => (
  prices.find((price: Stripe.Price) => price.product === product.id)
);

export const sortPlans = (firstPlan: Plan, secondPlan: Plan): null | number => (
  firstPlan.price.unit_amount! - secondPlan.price.unit_amount!
);

export const useStripe = () => {
  const { authState, update } = inject('AuthModule') as { authState: AuthState; update: Function };
  const { plans } = inject('PlansModule') as { plans: SubscriptionConfigurationType[] };

  const { updateUserRecord } = useFaunaDB();

  const customerId = authState.user?.stripeCustomerId;

  const fetchCurrentSubscription = async (id?: string): Promise<StripeSubscription> => (
    axios.post('/.netlify/functions/get-customer-subscription',
      { customerId: id || customerId },
      {
        headers: setAxiosHeaders(),
        force: true,
      })
      .then((response: AxiosResponse) => response.data.subscriptions.pop())
      .catch((error: AxiosError) => { Sentry.captureException(error); })
  );

  const fetchAllSubscriptions = async (): Promise<Stripe.SubscriptionItem[]> => (
    axios.get('/.netlify/functions/get-plans', { force: true })
      .then((response: AxiosResponse) => {
        const { products, prices } = response.data;

        return products
          .map((product: Stripe.Product) => ({ ...product, price: findPrice(product, prices) }))
          .sort(sortPlans);
      }).catch((error: AxiosError) => { Sentry.captureException(error); })
  );

  const updateSubscription = async (
    sub: StripeSubscription,
    plan: Stripe.SubscriptionItem,
    isUpgrade: boolean,
  ): Promise<boolean> => {
    const newSubscription = Object.values(plans).find(
      (subscription) => subscription.price === plan.price.id,
    );

    if (!newSubscription) return false;

    const shouldIncreaseNlpLimit = newSubscription.nlpRequestLimit > authState.user.subscription.nlpRequestLimit;
    const shouldIncreaseLimit = newSubscription.requestLimit > authState.user.subscription.requestLimit;
    const shouldIncreaseWordsLimit = newSubscription.wordsLimit > authState.user.subscription.wordsLimit;
    const shouldIncreaseTopicLimit = newSubscription.topicLimit > authState.user.subscription.topicLimit;
    const shouldIncreaseDatabaseAccess = newSubscription.databases > authState.user.subscription.databases;

    return axios.post(
      '/.netlify/functions/update-subscription',
      {
        subscriptionId: sub.id,
        priceId: plan.price.id,
        isUpgrade,
      },
      { headers: setAxiosHeaders() },
    ).then(() => {
      update({
        ...authState.user,
        subscription: newSubscription,
        currentNlpRequestLimit: shouldIncreaseNlpLimit
          ? authState.user.currentNlpRequestLimit + newSubscription.nlpRequestLimit
          : authState.user.currentNlpRequestLimit,
        currentRequestLimit: shouldIncreaseLimit
          ? authState.user.currentRequestLimit + newSubscription.requestLimit
          : authState.user.currentRequestLimit,
        currentDatabaseAccess: shouldIncreaseDatabaseAccess
          ? newSubscription.databases
          : authState.user.currentDatabaseAccess,
        currentWordsLimit: shouldIncreaseWordsLimit
          ? newSubscription.wordsLimit
          : authState.user.currentWordsLimit,
        currentTopicLimit: shouldIncreaseTopicLimit
          ? newSubscription.topicLimit
          : authState.user.currentTopicLimit,
      });

      return true;
    }).catch((error: AxiosError) => { Sentry.captureException(error); });
  };

  const fetchPaymentMethods = async (): Promise<Stripe.PaymentMethod[]> => (
    axios.post(
      '/.netlify/functions/get-customer-payment-methods',
      { customerId },
      { headers: setAxiosHeaders() },
    )
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => { Sentry.captureException(error); })
  );

  const redirectToCustomerPortal = async (): Promise<void | boolean> => (
    axios.post(
      '/.netlify/functions/create-customer-portal',
      { customerId },
      {
        headers: setAxiosHeaders(),
        force: true,
      },
    )
      .then((response: AxiosResponse) => {
        const { url } = response.data;
        window.location = url;
      }).catch((error: AxiosError) => { Sentry.captureException(error); })
  );

  const deleteCustomer = async (id?: string): Promise<boolean | null> => (
    axios.post(
      '/.netlify/functions/delete-customer',
      { customerId: id || customerId },
      { headers: setAxiosHeaders() },
    )
      .then(() => true)
      .catch((error: AxiosError) => { Sentry.captureException(error); })
  );

  const checkRenewalDate = async (renewalDate: number, id?: string): Promise<boolean> => {
    if (renewalDate > Date.now()) return true;

    const currentSubscription = await fetchCurrentSubscription(id);

    update({
      ...authState.user,
      requestCount: 0,
      nlpRequestCount: 0,
      renewalDate: currentSubscription.current_period_end,
      currentNlpRequestLimit: authState.user.subscription.nlpRequestLimit,
      currentRequestLimit: authState.user.subscription.requestLimit,
      currentDatabaseAccess: authState.user.subscription.databases,
    });

    const recordUpdateResponse = await updateUserRecord();

    if (!recordUpdateResponse) return false;

    return true;
  };

  return {
    fetchCurrentSubscription,
    fetchAllSubscriptions,
    fetchPaymentMethods,
    updateSubscription,
    redirectToCustomerPortal,
    deleteCustomer,
    checkRenewalDate,
  };
};
