
import { defineComponent } from 'vue';
import BaseCard from '@/components/base/BaseCard';

const SuggestedImagesSceleton = defineComponent({
  components: { BaseCard },

  setup() {
    const sceletonCards: number[] = [300, 190, 190, 300, 300, 190, 190, 300]; // sceleton item heights

    return { sceletonCards };
  },
});

export default SuggestedImagesSceleton;
