
import {
  defineComponent, ref, inject, reactive, computed,
} from 'vue';
import BaseCard from '@/components/base/BaseCard';
import BaseSelect from '@/components/base/BaseSelect.vue';
import SearchPlatformCheckbox from '@/components/SearchPlatformCheckbox.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import languages from '@/data/static/languages.json';
import SearchConfigurations from '@/constants/TextSearchAdvancedSettingsConfigurations';
import { VueI18n } from 'vue-i18n';
import SelectOption from '@/types/SelectOption';
import AuthState from '@/types/AuthState';
import SuggestionSettings from '@/types/SuggestionSettings';
import RequestStatus from '@/constants/RequestStatus';
import useFaunaDB from '@/mixins/useFaunaDB';
import SupportedLanguages from '@/constants/SupportedLanugages';
import * as Sentry from '@sentry/vue';

const TextSearchAdvancedSettings = defineComponent({
  components: {
    BaseCard, BaseSelect, SearchPlatformCheckbox, BaseButton,
  },

  emits: ['cancel', 'update:modelValue'],

  props: {
    modelValue: {
      type: Object as () => SuggestionSettings,
      required: true,
    },
    toggleButtonClass: String,
  },

  setup(props, { emit }) {
    const { updateUserRecord } = useFaunaDB();

    const i18n: VueI18n | undefined = inject('i18n');
    const { authState, update } = inject('AuthModule') as { authState: AuthState; update: Function };

    const status = ref<RequestStatus>(RequestStatus.Initial);
    const localValue = reactive<SuggestionSettings>({ ...props.modelValue });

    const isLoading = computed(() => status.value === RequestStatus.Loading);
    const isError = computed(() => status.value === RequestStatus.Error);

    const numberOfSimultaneousDB = computed(() => authState.user?.currentDatabaseAccess || 1);

    const selectedDatabases = ref<string[]>(
      localValue.image_database.split(',').slice(0, numberOfSimultaneousDB.value),
    );

    const isMaxSelected = computed(() => selectedDatabases.value.length > numberOfSimultaneousDB.value);

    const supportedSources = computed(() => (
      Object.entries(SupportedLanguages).map(
        (value) => value[1].includes(localValue.language) && value[0],
      )
    ));

    const platformOptions: SelectOption[] = SearchConfigurations.SearchPlatforms.map((platform) => ({
      value: platform,
      text: i18n && i18n.t(`dashboard.text-search-area.advanced-settings.platform-to-search-on.options.${platform}`),
    }));
    const languageOptions: SelectOption[] = Object.values(languages)
      .filter(
        (lang: { name: string; code: string }) => Object.values(SupportedLanguages).flat().includes(lang.code),
      )
      .map(
        (lang: { name: string; code: string }) => ({
          value: lang.code,
          text: lang.name,
        }),
      );

    // TODO:
    // In the future versions database and download sizes values will
    // be added in main user settings section too
    const updateUserSearchSettings = async () => {
      status.value = RequestStatus.Loading;

      update({
        ...authState.user,
        searchSettings: {
          databases: selectedDatabases.value,
          language: authState.user.searchSettings.language, // Ignore language change
          downloadSize: authState.user.searchSettings.downloadSize,
        },
      });

      const response = await updateUserRecord();

      if (!response) {
        status.value = RequestStatus.Error;
        Sentry.captureException(new Error('Failed to update user record from advanced settings'));

        return;
      }

      status.value = RequestStatus.Success;
    };

    const onApply = async () => {
      if (selectedDatabases.value.length === 0 || isMaxSelected.value) return;

      if (authState.isAuthenticated) await updateUserSearchSettings();

      localValue.image_database = selectedDatabases.value.join(',');
      emit('update:modelValue', localValue);
      emit('cancel');
    };

    const onCancel = () => {
      localValue.language = props.modelValue.language;
      localValue.image_database = props.modelValue.image_database;

      emit('cancel');
    };

    return {
      languageOptions,
      localValue,
      platformOptions,
      numberOfSimultaneousDB,
      onApply,
      onCancel,
      selectedDatabases,
      isMaxSelected,
      isLoading,
      isError,
      supportedSources,
    };
  },
});

export default TextSearchAdvancedSettings;
