
import { computed, defineComponent, ref } from 'vue';
import ImageDownloadSize from '@/types/ImageDownloadSize';

const DownloadSizesSelect = defineComponent({
  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: Object as () => ImageDownloadSize,
      required: true,
    },
    downloadOptions: {
      type: Array as () => ImageDownloadSize[],
      required: true,
    },
  },

  setup(props, { emit }) {
    const dropdownVisible = ref<boolean>(false);

    const localValue = computed({
      get: () => props.modelValue,
      set: (value: ImageDownloadSize) => {
        dropdownVisible.value = false;
        emit('update:modelValue', value);
      },
    });

    const isCurrentOption = (option: ImageDownloadSize) => (
      option.width === localValue.value.width && option.height === localValue.value.height
    );

    const toggleDropdown = () => {
      dropdownVisible.value = !dropdownVisible.value;
    };

    return {
      dropdownVisible,
      toggleDropdown,
      localValue,
      isCurrentOption,
    };
  },
});

export default DownloadSizesSelect;
