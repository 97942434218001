
import {
  defineComponent, inject, ref, computed, onMounted,
} from 'vue';
import Header from '@/components/Header.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import { useRouter, useRoute } from 'vue-router';
import RequestStatus from '@/constants/RequestStatus';
import useAuth from '@/mixins/useAuth';
import BaseAlertMessage from '@/components/base/BaseAlertMessage.vue';
import { useStripe } from '@/mixins/useStripe';
import useFaunaDB from '@/mixins/useFaunaDB';
import AuthState from '@/types/AuthState';
import useSignIn from '@/mixins/useSignIn';
import User from '@/types/User';
import SubscriptionPlans from '@/types/SubscriptionPlans';
import * as Sentry from '@sentry/vue';

const DefaultLayout = defineComponent({
  components: { Header, BaseLoading, BaseAlertMessage },

  props: {
    loading: Boolean,
    error: Boolean,
  },

  setup() {
    const { setPlans } = inject('PlansModule') as { plans: SubscriptionPlans; setPlans: Function };
    const { authState, update, logIn } = inject('AuthModule') as {
      authState: AuthState;
      logIn: Function;
      update: Function;
    };

    const {
      completeGoogleAuth, confirmEmail,
    } = useAuth();
    const { checkRenewalDate, fetchAllSubscriptions } = useStripe();
    const { updateUserRecord } = useFaunaDB();
    const { changeUserEmail } = useAuth();
    const { handleSignIn } = useSignIn();

    const route = useRoute();
    const router = useRouter();

    const emailConfirmed = ref<boolean>(false);
    const status = ref<RequestStatus>(RequestStatus.Initial);
    const subscriptionsStatus = ref<RequestStatus>(RequestStatus.Initial);

    const isLoading = computed(() => (status.value === RequestStatus.Loading));
    const isError = computed(() => (status.value === RequestStatus.Error));
    const isSubscriptionsError = computed(() => (subscriptionsStatus.value === RequestStatus.Error));

    const onGoogleAuth = async (code: string) => {
      status.value = RequestStatus.Loading;

      const userRecord = await completeGoogleAuth(code);

      if (!userRecord) {
        status.value = RequestStatus.Error;
        Sentry.captureException(new Error('No user record returned from Google Auth'));
        return;
      }

      // Check if user already exist
      const userExist = await handleSignIn(userRecord as User);

      if (userExist) {
        status.value = RequestStatus.Success;
        return;
      }

      logIn(userRecord);

      status.value = RequestStatus.Success;
    };

    const onRecoverAccount = (token: string) => {
      router.push({ name: 'reset-password', query: { token } });
    };

    const onChangeEmail = async (token: string) => {
      // TODO: Handle loading spinner
      const result = await changeUserEmail(token);

      if (!result) return;

      update(result.data);

      const response = await updateUserRecord();

      if (response) emailConfirmed.value = true;
    };

    const onConfirmEmail = async (token: string) => {
      status.value = RequestStatus.Loading;

      const userRecord = await confirmEmail(token);

      if (!userRecord) {
        status.value = RequestStatus.Error;
        Sentry.captureException(new Error('No user record returned from confirm email'));
        return;
      }

      logIn(userRecord);
      status.value = RequestStatus.Success;
    };

    const fetchSubscriptions = async () => {
      subscriptionsStatus.value = RequestStatus.Loading;

      const subscriptions = await fetchAllSubscriptions();

      if (!subscriptions) {
        subscriptionsStatus.value = RequestStatus.Error;
        Sentry.captureException(new Error('No subscriptions returned from fetch subscriptions'));
        return;
      }

      setPlans(subscriptions);
      subscriptionsStatus.value = RequestStatus.Success;
    };

    onMounted(async () => {
      const [name, token] = route.hash.substring(1).split('=');
      // Google access token
      if (name === 'authorization_code') onGoogleAuth(token);
      // Email confirmation token
      if (name === 'confirmation_token') onConfirmEmail(token);
      // Email change token
      if (name === 'email_change_token') onChangeEmail(token);
      // Password change token
      if (name === 'recovery_token') return onRecoverAccount(token);

      if (token) {
        router.push({ name: 'dashboard' });
      }

      // Check if renewal date
      if (authState.isAuthenticated) {
        const renewalDateInMilliseconds = authState.user.renewalDate * 1000;
        const renewalDateResponse = await checkRenewalDate(
          renewalDateInMilliseconds,
          authState.user.stripeCustomerId,
        );

        if (!renewalDateResponse) {
          status.value = RequestStatus.Error;
          Sentry.captureException(new Error('No renewal date returned from check renewal date'));
        }
      }

      await fetchSubscriptions();
    });

    return {
      isLoading,
      isError,
      emailConfirmed,
      isSubscriptionsError,
    };
  },
});

export default DefaultLayout;
