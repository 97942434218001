
import { defineComponent, inject } from 'vue';
import { VueI18n } from 'vue-i18n';
import FooterMenu from '@/types/FooterMenu';

const Footer = defineComponent({
  setup() {
    const i18n: VueI18n | undefined = inject('i18n');

    const footerMenu: FooterMenu[] = [
      {
        title: i18n?.t('footer.product') || '',
        links: [
          {
            name: i18n?.t('footer.how-it-works') || '',
            href: 'https://imagesuggest.com/#easy-steps',
          },
          {
            name: i18n?.t('footer.features') || '',
            href: 'https://imagesuggest.com/#features',
          },
          {
            name: i18n?.t('footer.add-on') || '',
            href: 'https://imagesuggest.com/#add-on',
          },
          {
            name: i18n?.t('footer.pricing') || '',
            href: 'https://imagesuggest.com/pricing',
          },
        ],
      },
      {
        title: i18n?.t('footer.support') || '',
        links: [
          {
            name: i18n?.t('footer.help-faq') || '',
            href: 'https://imagesuggest.com/pricing/#FAQ',
          },
          {
            name: i18n?.t('footer.privacy-policy') || '',
            href: 'https://imagesuggest.com/privacy-policy/',
          },
          {
            name: i18n?.t('footer.term-conditions') || '',
            href: 'https://imagesuggest.com/terms-and-conditions/',
          },
        ],
      },
      {
        title: i18n?.t('footer.company') || '',
        links: [
          {
            name: i18n?.t('footer.about-us') || '',
            href: 'https://imagesuggest.com/about-us/',
          },
          {
            name: i18n?.t('footer.contact-us') || '',
            href: 'https://imagesuggest.com/contact-us/',
          },
          {
            name: i18n?.t('footer.blog') || '',
            href: 'https://imagesuggest.com/blog/',
          },
        ],
      },
    ];

    return { footerMenu };
  },
});

export default Footer;
