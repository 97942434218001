import { inject } from 'vue';
import axios from '@/setup/axios';
import AuthState from '@/types/AuthState';
import { AxiosError, AxiosResponse } from 'axios';
import SuggestionRequest from '@/types/SuggestionRequest';
import { setAxiosHeaders } from '@/utils/api';
import * as Sentry from '@sentry/vue';

const useFaunaDB = () => {
  const { authState } = inject('AuthModule') as { update: Function; authState: AuthState };
  const retrieveUserRecord = async (id?: string) => (
    axios.post(
      '/.netlify/functions/retrieve-user',
      { imgsId: id || authState.user?.imgsId },
      {
        headers: setAxiosHeaders(),
        force: true,
      },
    )
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => { Sentry.captureException(error); })
  );

  const updateUserRecord = async () => (
    axios.post(
      '/.netlify/functions/update-user',
      { user: authState.user },
      {
        headers: setAxiosHeaders(),
        force: true,
      },
    )
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => { Sentry.captureException(error); })
  );

  const updateRequestRecord = async (
    url: string,
    data: SuggestionRequest,
    response: Record<string, any>[],
    sendOn: number,
    status: number,
  ) => (
    axios.post(
      '/.netlify/functions/create-request',
      {
        call: {
          user: authState.user?.imgsId,
          request: {
            url,
            params: data,
          },
          response: {
            items: response,
            responseTime: new Date().getTime() - sendOn,
            status,
          },
        },
      },
      { headers: setAxiosHeaders() },
    )
      .then(() => true)
      .catch((error: AxiosError) => { Sentry.captureException(error); })
  );

  const deleteUserRecord = async () => {
    const { user } = authState;

    return axios.post(
      '/.netlify/functions/delete-user',
      { user },
      { headers: setAxiosHeaders() },
    )
      .then(() => true)
      .catch((error: AxiosError) => { Sentry.captureException(error); });
  };

  return {
    retrieveUserRecord,
    updateUserRecord,
    deleteUserRecord,
    updateRequestRecord,
  };
};

export default useFaunaDB;
