
import {
  defineComponent, inject, ref, computed, reactive,
} from 'vue';
import TextSearchAreaTabs from '@/constants/TextSearchAreaTabs';
import BaseButton from '@/components/base/BaseButton.vue';
import TextSearchAdvancedSettings from '@/components/TextSearch/TextSearchAdvancedSettings.vue';
import SearchConfigurations from '@/constants/TextSearchAdvancedSettingsConfigurations';
import AuthState from '@/types/AuthState';
import SubscriptionPlans from '@/types/SubscriptionPlans';
import SearchPlatforms from '@/constants/SearchPlatforms';
import AutomaticSearchField from '@/components/TextSearch/AutomaticSearchField.vue';
import ManualSearchField from '@/components/TextSearch/ManualSearchField.vue';
import TextSearchAreaParameters from '@/types/TextSearchAreaParameters';
import BaseAlertMessage from '@/components/base/BaseAlertMessage.vue';
import { truncateText } from '@/utils/text';

const TextSearchArea = defineComponent({
  components: {
    BaseButton,
    TextSearchAdvancedSettings,
    AutomaticSearchField,
    ManualSearchField,
    BaseAlertMessage,
  },

  emits: ['submit', 'tab-changed'],

  props: {
    loading: Boolean,
    searchString: {
      type: String,
      required: true,
    },
    selectedTab: {
      type: String as () => TextSearchAreaTabs,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { authState } = inject('AuthModule') as { authState: AuthState };
    const { plans } = inject('PlansModule') as { plans: SubscriptionPlans };

    const tabs: TextSearchAreaTabs[] = Object.values(TextSearchAreaTabs);

    const yourTextTab: TextSearchAreaTabs = TextSearchAreaTabs.YourText;
    const isSettingsVisible = ref<boolean>(false);

    const requestParameters: TextSearchAreaParameters = reactive({
      submitted_text: props.searchString,
      submission_type: props.selectedTab,
    });

    const suggestionSettings = ref({
      language: authState.user?.searchSettings.language || SearchConfigurations.DefaultLanguage,
      image_database: authState.user?.searchSettings.databases.join(',')
        || Object.values(SearchPlatforms).splice(0, 1).join(','),
    });

    const canUpgrade = computed(() => authState.user?.subscription.price !== plans.standardPlan.price);

    const planLimit = computed(() => (requestParameters.submission_type === TextSearchAreaTabs.YourText
      ? authState.user?.currentWordsLimit || 500
      : authState.user?.currentTopicLimit || 1));

    const isMaxTextLength = computed(() => (
      requestParameters.submission_type === TextSearchAreaTabs.YourText
        ? truncateText(requestParameters.submitted_text).split(' ').length > planLimit.value // seperate by word
        : truncateText(requestParameters.submitted_text)
          .split(',').filter((word) => !!word).length > planLimit.value)); // seperate by keyword

    const isActiveTab = (tab: TextSearchAreaTabs): boolean => requestParameters.submission_type === tab;

    const changeTab = (tab: TextSearchAreaTabs): void => {
      requestParameters.submitted_text = '';
      requestParameters.submission_type = tab;
      emit('tab-changed');
    };

    const toggleSettings = (): void => {
      isSettingsVisible.value = !isSettingsVisible.value;
    };

    const onSearch = () => {
      emit('submit', { ...requestParameters, ...suggestionSettings.value });
    };

    return {
      tabs,
      isActiveTab,
      yourTextTab,
      changeTab,
      isSettingsVisible,
      toggleSettings,
      requestParameters,
      onSearch,
      suggestionSettings,
      isMaxTextLength,
      canUpgrade,
      planLimit,
    };
  },
});

export default TextSearchArea;
