
import {
  computed, WritableComputedRef, defineComponent, ref,
} from 'vue';
import { Field, ErrorMessage } from 'vee-validate';
import BaseLabel from '@/components/base/BaseLabel.vue';

const BaseInput = defineComponent({
  components: { BaseLabel, Field, ErrorMessage },

  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: String || Number,
      required: true,
    },
    label: String,
    tooltip: String,
    inputType: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    rules: String,
    placeholder: String,
    icon: Object as () => string[],
    iconLeft: Boolean,
    inputClasses: String,
    labelClasses: [String, Boolean],
    isValid: {
      type: Boolean,
      default: true,
    },
    plaintext: Boolean,
    clearable: Boolean,
  },

  setup(props, { emit }) {
    const inputVisible = ref<boolean>(false);

    const localValue: WritableComputedRef<string | number> = computed({
      get: (): string | number => props.modelValue,
      set: (value: string | number): void => emit('update:modelValue', value),
    });

    const handleIconClick = (): void => {
      if (props.clearable) localValue.value = '';
    };

    return { localValue, inputVisible, handleIconClick };
  },
});

export default BaseInput;
