import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "mt-1 relative" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "ml-3 block truncate" }
const _hoisted_4 = { class: "ml-3 absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none" }
const _hoisted_5 = {
  key: 0,
  class: "absolute mt-1 w-min rounded-md bg-white z-50"
}
const _hoisted_6 = {
  tabindex: "-1",
  role: "listbox",
  "aria-labelledby": "listbox-label",
  "aria-activedescendant": "listbox-item-3",
  class: "max-h-52 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto\n          focus:outline-none sm:text-14"
}
const _hoisted_7 = { class: "flex items-center" }
const _hoisted_8 = { class: "ml-3 block truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLabel = _resolveComponent("BaseLabel")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _directive_click_outside = _resolveDirective("click-outside")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_BaseLabel, {
      class: "mb-2",
      "label-classes": "text-input-label font-semibold",
      "tooltip-icon-classes": "hover:text-input-label text-18",
      label: _ctx.label,
      tooltip: _ctx.tooltip,
      "tooltip-position": _ctx.tooltipPosition
    }, null, 8, ["label", "tooltip", "tooltip-position"]),
    _withDirectives(_createVNode("div", _hoisted_1, [
      _withDirectives(_createVNode("button", {
        type: "button",
        class: "relative bg-white border border-input-main rounded-md pl-2 pr-10 py-2 text-left\n        cursor-default focus:outline-none sm:text-14",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleOptions && _ctx.toggleOptions(...args)))
      }, [
        _createVNode("span", _hoisted_2, [
          _createVNode("span", _hoisted_3, _toDisplayString(_ctx.localValue), 1)
        ]),
        _createVNode("span", _hoisted_4, [
          _createVNode(_component_font_awesome_icon, {
            class: "ml-2",
            icon: "chevron-down"
          })
        ])
      ], 512), [
        [_directive_click_outside, _ctx.hideOptions]
      ]),
      (_ctx.optionsVisible)
        ? (_openBlock(), _createBlock("div", _hoisted_5, [
            _createVNode("ul", _hoisted_6, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                return (_openBlock(), _createBlock("li", {
                  key: index,
                  role: "option",
                  class: [{ 'font-bold': _ctx.localValue === item.text }, "select-none relative py-2 pl-3 pr-9 hover:bg-list-hover"],
                  onClick: ($event: any) => (_ctx.selectOption(item))
                }, [
                  _createVNode("div", _hoisted_7, [
                    _createVNode("span", _hoisted_8, _toDisplayString(item.text), 1)
                  ])
                ], 10, ["onClick"]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, !_ctx.plaintext]
    ]),
    _withDirectives(_createVNode("div", { class: "text-input-plaintext" }, _toDisplayString(_ctx.localValue), 513), [
      [_vShow, _ctx.plaintext]
    ])
  ]))
}