import { defineRule } from 'vee-validate';
import i18n from '@/setup/i18n';

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

defineRule('required', (value: string) => {
  if (!value || !value.length) {
    return i18n.global.t('validation.required');
  }

  return true;
});

defineRule('email', (value: string) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }

  // Check if email
  if (!emailRegex.test(value)) {
    return i18n.global.t('validation.email');
  }

  return true;
});

defineRule('min', (value: string, [limit]: number[]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true;
  }

  if (value.length < limit) {
    return i18n.global.t('validation.limit', { limit });
  }

  return true;
});

defineRule('confirmed', (value: string, [target]: string[]) => {
  if (value === target) {
    return true;
  }

  return i18n.global.t('validation.password');
});
