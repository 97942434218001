
import {
  computed, WritableComputedRef, defineComponent, ref,
} from 'vue';
import SelectOption from '@/types/SelectOption';
import BaseLabel from '@/components/base/BaseLabel.vue';

const BaseSelect = defineComponent({
  components: { BaseLabel },

  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: String || Number || Boolean,
      required: true,
    },
    label: String,
    items: {
      type: Object as () => SelectOption[],
      default: () => [],
    },
    tooltip: String,
    tooltipPosition: String,
    plaintext: Boolean,
  },

  setup(props, { emit }) {
    const optionsVisible = ref<boolean>(false);

    const localValue: WritableComputedRef<string | number | boolean> = computed({
      get: (): string | number | boolean => props.items.find((item) => item.value === props.modelValue)?.text || '',
      set: (value: string | number | boolean): void => emit('update:modelValue', value),
    });

    const toggleOptions = (): void => {
      optionsVisible.value = !optionsVisible.value;
    };

    const selectOption = (option: SelectOption): void => {
      optionsVisible.value = false;
      localValue.value = option.value || '';
    };

    const hideOptions = (): void => {
      if (optionsVisible.value) optionsVisible.value = false;
    };

    return {
      localValue, optionsVisible, toggleOptions, selectOption, hideOptions,
    };
  },
});

export default BaseSelect;
