import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_BaseLoading = _resolveComponent("BaseLoading")

  return (_openBlock(), _createBlock("button", {
    class: [
      `rounded transition-all duration-300 relative focus:outline-none`,
      _ctx.outline && `border border-btn-${_ctx.variant} text-btn-${_ctx.variant} hover:bg-btn-${_ctx.variant} hover:text-white`,
      !_ctx.link ? `active:bg-btn-${_ctx.variant}-dark` : 'font-normal hover:opacity-75',
      (!_ctx.outline && !_ctx.link) && `text-white bg-btn-${_ctx.variant} hover:bg-btn-${_ctx.variant}-light`,
      { 'px-5 py-2 font-semibold': !_ctx.link && _ctx.size === 'sm' },
      { 'px-6 py-2.5 font-semibold': !_ctx.link && _ctx.size === 'normal' },
      { 'px-8 py-3.5 font-bold': !_ctx.link && _ctx.size === 'lg' },
      { 'pr-14': _ctx.loading },
      { 'bg-btn-secondary-dark cursor-not-allowed ': _ctx.disabled },
    ],
    disabled: _ctx.disabled
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          icon: _ctx.icon,
          class: "mr-2"
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_BaseLoading, {
          key: 1,
          class: "absolute top-1/4 h-2/4 right-4 ml-3 flex items-center",
          variant: _ctx.loadingVariant,
          "bar-width": _ctx.loadingBarWidth
        }, null, 8, ["variant", "bar-width"]))
      : _createCommentVNode("", true)
  ], 10, ["disabled"]))
}