
import { ref, defineComponent, watch } from 'vue';
import SelectOption from '@/types/SelectOption';
import BaseLabel from '@/components/base/BaseLabel.vue';

const SearchPlatformCheckbox = defineComponent({
  components: { BaseLabel },

  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: Object as () => string[],
      required: true,
    },
    label: String,
    tooltip: [String, Boolean],
    options: {
      type: Object as () => SelectOption[],
      default: Object as () => [],
    },
    supported: {
      type: Object as () => string[],
      default: Object as () => [],
    },
    inline: Boolean,
    variant: {
      type: String,
      default: 'primary',
      validator: (value: string): boolean => (
        ['primary', 'secondary', 'dark', 'light'].indexOf(value) !== -1
      ),
    },
    checkboxClasses: String,
    checkboxLabelClasses: String,
    uncheckedIconClasses: String,
    checkedIconClasses: String,
  },

  setup(props, { emit }) {
    const checked = ref<string[]>([...props.modelValue]);

    const isChecked = (value: string): boolean => (checked.value.indexOf(value) >= 0);

    const isDisabled = (option: SelectOption): boolean => (
      option.disabled || (props.supported.length > 0 && !props.supported.includes(option.value as string))
    );

    const toggleValue = (value: string): void => {
      if (checked.value.indexOf(value) >= 0) {
        checked.value.splice(checked.value.indexOf(value), 1);
      } else {
        checked.value.push(value);
      }

      emit('update:modelValue', checked.value);
    };

    watch(() => props.supported, (supported) => {
      const newValue = checked.value.filter((val) => supported.includes(val));
      checked.value = newValue;
      emit('update:modelValue', newValue);
    });

    return { toggleValue, isChecked, isDisabled };
  },
});

export default SearchPlatformCheckbox;
