import { reactive, readonly } from 'vue';
import Stripe from 'stripe';
import SubscriptionConfigurationType from '@/types/SubscriptionConfigurationType';

const products = {
  Free: process.env.VUE_APP_STRIPE_FREE_PRODUCT,
  Standard: process.env.VUE_APP_STRIPE_STANDARD_PRODUCT,
};

const PlansModule = () => {
  const plans = reactive({
    freePlan: {} as SubscriptionConfigurationType,
    standardPlan: {} as SubscriptionConfigurationType,
  });

  const setPlans = (subscriptions: Stripe.SubscriptionItem[]): void => {
    const freeSubscription = subscriptions.find((sub: Stripe.SubscriptionItem) => sub.id === products.Free);
    const standardSubscription = subscriptions.find((sub: Stripe.SubscriptionItem) => sub.id === products.Standard);

    plans.freePlan = {
      price: freeSubscription?.price.id || '',
      requestLimit: Number(freeSubscription?.metadata.manual), // number of topic searches
      nlpRequestLimit: Number(freeSubscription?.metadata.recommendations), // number of AI powered searches
      wordsLimit: Number(freeSubscription?.metadata.words), // limit of words per AI search
      topicLimit: Number(freeSubscription?.metadata.keywords), // limit of topics per basic search
      databases: Number(freeSubscription?.metadata.databases), // available databases
    };

    plans.standardPlan = {
      price: standardSubscription?.price.id || '',
      requestLimit: Number(standardSubscription?.metadata.manual),
      nlpRequestLimit: Number(standardSubscription?.metadata.recommendations),
      wordsLimit: Number(standardSubscription?.metadata.words),
      topicLimit: Number(standardSubscription?.metadata.keywords),
      databases: Number(standardSubscription?.metadata.databases),
    };
  };

  return {
    setPlans,
    plans: readonly(plans),
  };
};

export default PlansModule;
