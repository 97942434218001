import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pb-16 px-4 md:px-20 mt-5 sm:mt-10" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 2,
  class: "text-center text-22 text-muted pt-12 pb-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SuggestedImagesSceleton = _resolveComponent("SuggestedImagesSceleton")
  const _component_SuggestedImagesFilters = _resolveComponent("SuggestedImagesFilters")
  const _component_SuggestedImage = _resolveComponent("SuggestedImage")
  const _component_MasonryLayout = _resolveComponent("MasonryLayout")
  const _component_RequireSignUp = _resolveComponent("RequireSignUp")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (!_ctx.allImagesLoaded)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          style: `width: ${_ctx.progress}%`,
          class: "bg-btn-primary h-2 w-0"
        }, null, 4))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        (_ctx.loading || !_ctx.allImagesLoaded)
          ? (_openBlock(), _createBlock(_component_SuggestedImagesSceleton, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode("div", {
          class: {'hidden': _ctx.loading || !_ctx.allImagesLoaded}
        }, [
          (_ctx.images.length > 0)
            ? (_openBlock(), _createBlock(_component_SuggestedImagesFilters, {
                key: 0,
                class: "mb-5",
                modelValue: _ctx.filterOptions,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterOptions = $event)),
                "selected-databases": _ctx.selectedDatabases,
                suggested: _ctx.filteredImages.length
              }, null, 8, ["modelValue", "selected-databases", "suggested"]))
            : _createCommentVNode("", true),
          (_ctx.filteredImages.length > 0)
            ? (_openBlock(), _createBlock(_component_MasonryLayout, {
                key: 1,
                items: _ctx.filteredImages,
                "content-loaded": _ctx.allImagesLoaded,
                height: 850
              }, {
                default: _withCtx((item) => [
                  (!_ctx.isBroken(item))
                    ? (_openBlock(), _createBlock("div", _hoisted_3, [
                        _createVNode(_component_SuggestedImage, {
                          item: item,
                          onLoad: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadedImages++)),
                          onError: _ctx.onError
                        }, null, 8, ["item", "onError"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["items", "content-loaded"]))
            : (_openBlock(), _createBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('messages.no-results')), 1)),
          (_ctx.requireAuth && _ctx.images.length > 0)
            ? (_openBlock(), _createBlock(_component_RequireSignUp, { key: 3 }))
            : _createCommentVNode("", true)
        ], 2)
      ])
    ])
  ], 64))
}