import { h, Slots } from 'vue';

const BaseCard = (props: {}, context: { attrs: Record<string, unknown>; slots: Slots }) => h(
  'div',
  {
    ...context.attrs,
    class: 'bg-white rounded-md',
  },
  context.slots,
);

export default BaseCard;
