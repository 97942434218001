import axios from '@/setup/axios';
import { AxiosError, AxiosResponse } from 'axios';
import User from '@/types/User';
import { setAxiosHeaders } from '@/utils/api';
import * as Sentry from '@sentry/vue';

const useAuth = () => {
  const googleAuth = async () => {
    window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?'
    + `client_id=${process.env.VUE_APP_GOOGLE_CLIENT_ID}&`
    + 'response_type=code&'
    + 'state=state_parameter_passthrough_value&'
    + 'scope=https://www.googleapis.com/auth/userinfo.email&'
    + `redirect_uri=${process.env.VUE_APP_BASE_URL}&`
    + 'prompt=consent&'
    + 'include_granted_scopes=true';
  };

  const completeGoogleAuth = async (code: string): Promise<boolean | User> => {
    // exchange code for token
    const idToken = await axios.post(
      'https://oauth2.googleapis.com/token?'
      + `code=${code}&`
      + `client_id=${process.env.VUE_APP_GOOGLE_CLIENT_ID}&`
      + `client_secret=${process.env.VUE_APP_GOOGLE_CLIENT_SECRET}&`
      + `redirect_uri=${process.env.VUE_APP_BASE_URL}&`
      + 'grant_type=authorization_code',
      { force: true },
    )
      .then((response: AxiosResponse) => response?.data?.id_token)
      .catch((error: AxiosError) => (Sentry.captureException(error)));

    return axios.post(
      '/.netlify/functions/google-auth',
      { token: idToken },
      { force: true },
    )
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => (Sentry.captureException(error)));
  };

  const signUp = async (email: string, password: string): Promise<User> => (
    axios.post(
      '/.netlify/functions/sign-up',
      { email, password, needConfirm: true },
      { force: true },
    )
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => (Sentry.captureException(error)))
  );

  const changeUserEmail = async (token: string) => (
    axios.post(
      '/.netlify/functions/change-user-email',
      { token },
      {
        headers: setAxiosHeaders(),
        force: true,
      },
    )
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => (Sentry.captureException(error)))
  );

  const changeUserPassword = async (password: string) => (
    axios.post(
      '/.netlify/functions/change-user-password',
      { password },
      {
        headers: setAxiosHeaders(),
        force: true,
      },
    )
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => (Sentry.captureException(error)))
  );

  const recoverUserPassword = async (password: string, token: string) => (
    axios.post(
      '/.netlify/functions/recover-user-password',
      { password, token },
      {
        force: true,
      },
    )
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => (Sentry.captureException(error)))
  );

  const confirmEmail = async (token: string): Promise<User> => (
    axios.post(
      '/.netlify/functions/confirm-email',
      { token },
    )
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => (Sentry.captureException(error)))
  );

  const signIn = async (email: string, password: string) => (
    axios.post(
      '/.netlify/functions/sign-in',
      { email, password, fromAddon: false },
      { force: true },
    )
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => (Sentry.captureException(error)))
  );

  const signOut = async () => true;

  const requestPasswordRecovery = async (email: string): Promise<boolean> => (
    axios.post(
      '/.netlify/functions/request-password-reset',
      { email },
    )
      .then((response: AxiosResponse) => response.data)
      .catch((error: AxiosError) => (Sentry.captureException(error)))
  );

  return {
    signUp,
    signIn,
    signOut,
    requestPasswordRecovery,
    confirmEmail,
    googleAuth,
    completeGoogleAuth,
    changeUserEmail,
    changeUserPassword,
    recoverUserPassword,
  };
};

export default useAuth;
