
import {
  defineComponent,
  computed,
  ref, onMounted,
} from 'vue';
import { truncateText } from '@/utils/text';

const AutomatiSearchField = defineComponent({
  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const highlighted = ref<string>('');

    const localValue = computed({
      get: () => props.modelValue,
      set: (value: string) => emit('update:modelValue', value),
    });

    const contentWords = computed(() => (highlighted.value
      ? truncateText(highlighted.value).split(' ').length
      : truncateText(localValue.value).split(' ').length));

    const handleTextHighlight = () => {
      const { activeElement } = document;
      if (activeElement && activeElement.id === 'submittest-text') {
        highlighted.value = document.getSelection()?.toString() || '';
      }
    };

    onMounted(() => {
      document.addEventListener('selectionchange', handleTextHighlight);
    });

    return {
      localValue,
      contentWords,
    };
  },
});

export default AutomatiSearchField;
