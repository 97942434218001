
import { defineComponent } from 'vue';
import BaseLoading from '@/components/base/BaseLoading.vue';

const BaseButton = defineComponent({
  components: { BaseLoading },

  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: (value: string): boolean => (
        ['primary', 'secondary', 'success', 'danger', 'dark', 'close'].indexOf(value) !== -1
      ),
    },
    loading: Boolean,
    outline: Boolean,
    size: {
      type: String,
      default: 'normal',
    },
    icon: Object as () => string,
    link: Boolean,
    disabled: Boolean,
    loadingVariant: String,
    loadingBarWidth: String,
  },
});

export default BaseButton;
