import { reactive, readonly } from 'vue';
import User from '@/types/User';
import AuthState from '@/types/AuthState';
import * as Sentry from '@sentry/vue';

const AuthModule = () => {
  const authState = reactive<AuthState>({
    isAuthenticated: JSON.parse(localStorage.getItem('user') || 'null') !== null,
    user: JSON.parse(localStorage.getItem('user') || 'null'),
  });

  const logIn = (user: User): void => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
      authState.user = user;
      authState.isAuthenticated = true;
      Sentry.setUser({ email: user.email, id: user.imgsId });
    }
  };

  const logOut = (): void => {
    localStorage.removeItem('user');
    authState.user = null as unknown as User;
    authState.isAuthenticated = false;
  };

  const update = (user: User): void => {
    const token = authState.user?.token;
    authState.user = { ...user, token };
    authState.isAuthenticated = true;
    localStorage.setItem('user', JSON.stringify({ ...user, token }));
  };

  return {
    logIn, logOut, update, authState: readonly(authState),
  };
};

export default AuthModule;
