import { computed, ref } from 'vue';
import useAuth from '@/mixins/useAuth';
import RequestStatus from '@/constants/RequestStatus';
import * as Sentry from '@sentry/vue';

const useSignUp = {
  setup() {
    const { signUp } = useAuth();

    const email = ref<string>('');
    const password = ref<string>('');
    const confirmationEmail = ref<string>('');
    const isConfirmationSent = ref<boolean>(false);
    const status = ref<RequestStatus>(RequestStatus.Initial);

    const isLoading = computed(() => status.value === RequestStatus.Loading);
    const isError = computed(() => status.value === RequestStatus.Error);

    const onSuccessSignUp = (userEmail: string): void => {
      isConfirmationSent.value = true;
      confirmationEmail.value = userEmail;
    };

    const onSubmit = async (): Promise<void> => {
      status.value = RequestStatus.Loading;

      const response = await signUp(email.value, password.value);
      if (!response) {
        status.value = RequestStatus.Error;
        Sentry.captureException(new Error('Could not sign up'));

        return;
      }

      status.value = RequestStatus.Success;
      onSuccessSignUp(email.value);
    };

    return {
      email,
      password,
      isLoading,
      isError,
      onSubmit,
      confirmationEmail,
      isConfirmationSent,
    };
  },
};

export default useSignUp;
