import { DirectiveBinding } from 'vue';

const handleClickOutside = (el: HTMLElement, binding: DirectiveBinding) => (event: Event) => {
  if (!(el === event.target || el.contains(event.target as (Node | null)))) binding.value();
};

const ClickOutside = {
  beforeMount: (el: HTMLElement, binding: DirectiveBinding) => {
    document.addEventListener('click', handleClickOutside(el, binding));
  },
  unmounted: (el: HTMLElement, binding: DirectiveBinding) => {
    document.removeEventListener('click', handleClickOutside(el, binding));
  },
};

export default ClickOutside;
