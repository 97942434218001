
import {
  defineComponent, ref, inject,
} from 'vue';
import { useRouter } from 'vue-router';
import BaseButton from '@/components/base/BaseButton.vue';
import PlanLabel from '@/components/PlanLabel.vue';

const UserProfileDrowdown = defineComponent({
  components: { BaseButton, PlanLabel },

  props: {
    userName: String,
  },

  setup() {
    const router = useRouter();

    const { logOut } = inject('AuthModule') as { logOut: Function };

    const dropdownVisible = ref<boolean>(false);

    const toggleDropdown = (): void => {
      dropdownVisible.value = !dropdownVisible.value;
    };

    const hideDropdown = (): void => {
      if (dropdownVisible.value) dropdownVisible.value = false;
    };

    const onLogOut = async () => {
      logOut();
      router.push({ name: 'dashboard' });
    };

    return {
      dropdownVisible, toggleDropdown, hideDropdown, onLogOut,
    };
  },
});

export default UserProfileDrowdown;
